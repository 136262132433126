import { Injectable } from "@angular/core";

@Injectable()
export class UtilsService {
  constructor() {}

  public parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }

  public getSystemDateTime() {
    const date = new Date();
    const fulldate = this.formatDate(date);
    const fulltime = this.formatTime(date);
    const fulldt = fulldate + " " + fulltime;
    return fulldt;
  }

  public formatDate(date) {
    let d = new Date(date),
      day = "" + d.getDate(),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  }

  public formatTime(date) {
    let d = new Date(date),
      hour = "" + d.getHours(),
      minute = "" + d.getMinutes(),
      second = "" + d.getSeconds();
    if (second.length < 2) {
      second = "0" + second;
    }
    if (minute.length < 2) {
      minute = "0" + minute;
    }
    return [hour, minute, second].join(":");
  }
}
