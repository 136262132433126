import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoadingController, Platform } from '@ionic/angular';
// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { BehaviorSubject } from 'rxjs';
import { resolve } from 'dns';
import { rejects } from 'assert';
const mainUrl = 'https://gwcarterapp.com/wp-json/jwt-auth/v1/';
const apiV1 = 'https://gwcarterapp.com/wp-json/mobileapi/v1/';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  loading: any;
  userLoginSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  totalPosts = null;
  pages: any;
  isPlatformAndroidIos: boolean = false;
  constructor(
    private http: HttpClient,
    private platform: Platform,
    // private oneSignal: OneSignal,
    public loadingCtrl: LoadingController,
  ) {
    const isapp: any = (document.URL.startsWith('http://app.gwcarterapp.com/') || document.URL.startsWith('https://app.gwcarterapp.com/') || document.URL.startsWith('http://localhost:8100'));
    if (isapp) {
      this.isPlatformAndroidIos = false;
      console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
    } else {
      this.isPlatformAndroidIos = true;
      console.log("isPlatformAndroidIos=", this.isPlatformAndroidIos)
    }
  }

  getURL() {
    return mainUrl;
  }

  generateRandomFourDigitNumber(url) {
    return url.match(/([^\/]+)(?=\.\w+$)/)[0];
    // return Math.floor(1000 + Math.random() * 9000);
  }

  async dismissLoading() {
    if (this.loading) {
      return await this.loading.dismiss();
    }
  }

  async showLoader(msg: string = '') {
    if (msg === '') {
      msg = 'Please wait...';
    }
    this.loading = await this.loadingCtrl.create({ message: msg });
    return await this.loading.present();
  }

  getData(endPoint) {
    return this.http.get(apiV1 + endPoint).pipe(map((data) => data));
  }

  sendData(endPoint, data) {
    return this.http.post(apiV1 + endPoint, data).pipe(map((result) => result));
  }
  doReset(email) {
    return this.http.post(mainUrl + 'forgotPassword', {
      email: email
    }).pipe(
      map(user => {
        return user;
      })
    )
  }
  getAbout() {
    return this.http.get(mainUrl + 'get_about_us');
  }

  doLogin(endPoint, data) {
    return this.http
      .post(mainUrl + endPoint, data)
      .pipe(map((result) => result));

  }


  async SaveAutoConfiqure(token) {
    if (this.platform.is('cordova')) {
      // this.oneSignal.getIds().then((id) => {
      //   this.saveOneSignID(token, id.userId).subscribe((m) => {});
      // });
    }
  }

  uploadDocumentOnShareFile(data) {
    return new Promise((resolve, rejects) => {
      this.sendData('upload_file_on_sharefile', data).subscribe((res) => {
        resolve(res)
      }, (err) => {
        rejects(err.error);
      });
    })
  }



}
