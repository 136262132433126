import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './services/auth/login.guard';
import { GuardGuard } from './services/auth/guard.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./beforeLoginPages/login/login.module').then( m => m.LoginPageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./beforeLoginPages/signup/signup.module').then( m => m.SignupPageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./beforeLoginPages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./beforeLoginPages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'spacial-code',
    loadChildren: () => import('./beforeLoginPages/spacial-code/spacial-code.module').then( m => m.SpacialCodePageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'about-us',
    loadChildren: () => import('./commonpages/about-us/about-us.module').then( m => m.AboutUsPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./commonpages/contact-us/contact-us.module').then( m => m.ContactUsPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./commonpages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./commonpages/help/help.module').then( m => m.HelpPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./commonpages/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./commonpages/notification/notification.module').then( m => m.NotificationPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'quote-request-one',
    loadChildren: () => import('./quoteRequestPages/quote-request-one/quote-request-one.module').then( m => m.QuoteRequestOnePageModule)
  },
  {
    path: 'quote-request-two',
    loadChildren: () => import('./quoteRequestPages/quote-request-two/quote-request-two.module').then( m => m.QuoteRequestTwoPageModule)
  },
  {
    path: 'quote-request-three',
    loadChildren: () => import('./quoteRequestPages/quote-request-three/quote-request-three.module').then( m => m.QuoteRequestThreePageModule)
  },
  {
    path: 'quote-request-four',
    loadChildren: () => import('./quoteRequestPages/quote-request-four/quote-request-four.module').then( m => m.QuoteRequestFourPageModule)
  },
  {
    path: 'quote-request-five',
    loadChildren: () => import('./quoteRequestPages/quote-request-five/quote-request-five.module').then( m => m.QuoteRequestFivePageModule)
  },
  {
    path: 'quote-request-six',
    loadChildren: () => import('./quoteRequestPages/quote-request-six/quote-request-six.module').then( m => m.QuoteRequestSixPageModule)
  },
  {
    path: 'quote-request-seven',
    loadChildren: () => import('./quoteRequestPages/quote-request-seven/quote-request-seven.module').then( m => m.QuoteRequestSevenPageModule)
  },
  {
    path: 'quote-request-eight',
    loadChildren: () => import('./quoteRequestPages/quote-request-eight/quote-request-eight.module').then( m => m.QuoteRequestEightPageModule)
  },
  {
    path: 'quote-request-nine',
    loadChildren: () => import('./quoteRequestPages/quote-request-nine/quote-request-nine.module').then( m => m.QuoteRequestNinePageModule)
  },
  {
    path: 'guest-home',
    loadChildren: () => import('./guestPages/guest-home/guest-home.module').then( m => m.GuestHomePageModule)
  },
  {
    path: 'chat-session',
    loadChildren: () => import('./guestPages/chat-session/chat-session.module').then( m => m.ChatSessionPageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'calendly',
    loadChildren: () => import('./guestPages/calendly/calendly.module').then( m => m.CalendlyPageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'started-session',
    loadChildren: () => import('./guestPages/started-session/started-session.module').then( m => m.StartedSessionPageModule),
  },
  {
    path: 'selected-service',
    loadChildren: () => import('./guestPages/selected-service/selected-service.module').then( m => m.SelectedServicePageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'client-home',
    loadChildren: () => import('./clientPages/client-home/client-home.module').then( m => m.ClientHomePageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'my-documents',
    loadChildren: () => import('./clientPages/my-documents/my-documents.module').then( m => m.MyDocumentsPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'my-project',
    loadChildren: () => import('./clientPages/my-project/my-project.module').then( m => m.MyProjectPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'my-tax-returns',
    loadChildren: () => import('./clientPages/my-tax-returns/my-tax-returns.module').then( m => m.MyTaxReturnsPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'tax-guides',
    loadChildren: () => import('./clientPages/tax-guides/tax-guides.module').then( m => m.TaxGuidesPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'extensions-requests',
    loadChildren: () => import('./clientPages/extensions-requests/extensions-requests.module').then( m => m.ExtensionsRequestsPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'extensions-requests-two',
    loadChildren: () => import('./clientPages/extensions-requests-two/extensions-requests-two.module').then( m => m.ExtensionsRequestsTwoPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'extensions-requests-no',
    loadChildren: () => import('./clientPages/extensions-requests-no/extensions-requests-no.module').then( m => m.ExtensionsRequestsNoPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'extensions-requests-yes',
    loadChildren: () => import('./clientPages/extensions-requests-yes/extensions-requests-yes.module').then( m => m.ExtensionsRequestsYesPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'learn-more',
    loadChildren: () => import('./clientPages/learn-more/learn-more.module').then( m => m.LearnMorePageModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./clientPages/details/details.module').then( m => m.DetailsPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'upload-document',
    loadChildren: () => import('./clientPages/upload-document/upload-document.module').then( m => m.UploadDocumentPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'e-sign',
    loadChildren: () => import('./clientPages/e-sign/e-sign.module').then( m => m.ESignPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'add-comment',
    loadChildren: () => import('./clientPages/add-comment/add-comment.module').then( m => m.AddCommentPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'selected-document',
    loadChildren: () => import('./clientPages/selected-document/selected-document.module').then( m => m.SelectedDocumentPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'selected-tax-guide',
    loadChildren: () => import('./clientPages/selected-tax-guide/selected-tax-guide.module').then( m => m.SelectedTaxGuidePageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./commonpages/tutorial/tutorial.module').then( m => m.TutorialPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'setting',
    loadChildren: () => import('./commonpages/setting/setting.module').then( m => m.SettingPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'project-list',
    loadChildren: () => import('./clientPages/project-list/project-list.module').then( m => m.ProjectListPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./beforeLoginPages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'term-of-service',
    loadChildren: () => import('./beforeLoginPages/term-of-service/term-of-service.module').then( m => m.TermOfServicePageModule),
    canActivate : [LoginGuard]
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./commonpages/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule),
    canActivate : [GuardGuard]
  },
  {
    path: 'first-time-tutorial',
    loadChildren: () => import('./commonpages/first-time-tutorial/first-time-tutorial.module').then( m => m.FirstTimeTutorialPageModule)
  },
  {
    path: 'document-request',
    loadChildren: () => import('./clientPages/document-request/document-request.module').then( m => m.DocumentRequestPageModule)
  },

  

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
