import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx'
import { AlertControllerService } from './alert-controller.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    public nativeStorage: NativeStorage,
    private baseService: AlertControllerService
  ) { }

  public saveToNativeStorage(name: string, value: any) {
    if (Capacitor.getPlatform() === 'web') {
      localStorage.setItem(name, value);
      return new Promise(async (resolve, reject) => {
        resolve('saveToNativeStorage');
      });
    }
    this.nativeStorage.setItem(name, {
      key: value
    }).then(
      () => {
      },
      error => {
        const message = 'Error - 60050';
        const details = ' saveTo NativeStorage: ' + name + ' ... ' + error + ' ... ' + JSON.stringify(error);
        this.baseService.presentAlertWithButtons(message);
      }
    );
  }

  public getFromNativeStorage(itemName: string): Promise<any> {
    if (Capacitor.getPlatform() === 'web') {
      return new Promise(async (resolve, reject) => {
        const data = localStorage.getItem(itemName);
        resolve(data);
      });
    }

    return this.nativeStorage.getItem(itemName).then(
      data => data,
      error => {
        const strignifiedError = ' failed to getFrom NativeStorage ... ' + itemName + ' ... ' + error + ' ... ' + JSON.stringify(error, null, 2);
        const msg = 'Error - 60051';
        if (strignifiedError.length > 6 && !(error.code === 2 || error.code === '2' || error.code === 'AccessDenied')) {
          this.baseService.presentAlertWithButtons(msg);
        } else {
          this.baseService.presentAlertWithButtons(msg + '     ...  ' + strignifiedError);
        }
        return null;
      }
    );
  }

  public removeFromNativeStorage(itemName: string) {
    if (Capacitor.getPlatform() === 'web') {
      localStorage.removeItem(itemName);
    } else {
      this.nativeStorage.remove(itemName).then(success => {
        this.baseService.presentAlertWithButtons('SuccessFully Removed' + itemName + JSON.stringify(success));
      }, error => {
        const strignifiedError = itemName + ' ... ' + error + ' ... ' + JSON.stringify(error);
        const msg = 'Error - 60052';
        if (strignifiedError.length > 6 && !(error.code === 2 || error.code === '2' || error.code === 'AccessDenied')) {
          this.baseService.presentAlertWithButtons(msg);
        } else {
          this.baseService.presentAlertWithButtons(msg + '     ...  ' + strignifiedError);
        }
      });
    }
  }

  public clearNativeStorage() {
    this.nativeStorage.clear();
    localStorage.clear();
  }

  public setRedirectionPath(firstTime) {
    const key = 'first_time_login';
    this.saveToNativeStorage(key, firstTime);
  }
}
