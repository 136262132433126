// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  is_dev: true,
  firebaseConfig : {
    apiKey: "AIzaSyB-If0J0ej80LR7FowT7Pv44V_xmqbarfE",
    authDomain: "gw-carter-b3a65.firebaseapp.com",
    databaseURL: "https://gw-carter-b3a65-default-rtdb.firebaseio.com",
    projectId: "gw-carter-b3a65",
    storageBucket: "gw-carter-b3a65.appspot.com",
    messagingSenderId: "236068449952",
    appId: "1:236068449952:web:7716f4a6c32471908223c5",
    measurementId: "G-ZL8Y4F74ED"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
