import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrefService } from './services/common/pref.service';
import { StorageEngineService } from './services/common/storage-engine.service';
import { LocalStorageService } from './services/common/local-storage.service';
import { UtilsService } from './services/common/utils.service';
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { TokenInterceptorService } from './token-interceptor.service';
import { ReactiveFormsModule } from '@angular/forms';
import { InAppBrowser, InAppBrowserEvent, InAppBrowserObject, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { initializeApp } from 'firebase/app';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

const firebaseConfig = {
  apiKey: "AIzaSyB-If0J0ej80LR7FowT7Pv44V_xmqbarfE",
  authDomain: "gw-carter-b3a65.firebaseapp.com",
  databaseURL: "https://gw-carter-b3a65-default-rtdb.firebaseio.com",
  projectId: "gw-carter-b3a65",
  storageBucket: "gw-carter-b3a65.appspot.com",
  messagingSenderId: "236068449952",
  appId: "1:236068449952:web:7716f4a6c32471908223c5",
  measurementId: "G-ZL8Y4F74ED"
};

const firebaseApp = initializeApp(firebaseConfig);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,HttpClientModule, IonicModule.forRoot({
    mode:"ios"
  }), AppRoutingModule,ReactiveFormsModule],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    SplashScreen,
    StatusBar,
    NativeStorage,
    CallNumber,
    StorageEngineService,
    LocalStorageService,
    UtilsService,
    File,
    PrefService,
    InAppBrowser,
    FCM,
    FileTransfer,
    //  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Device,
    AndroidPermissions
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {}
