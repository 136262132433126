import { Component, NgZone, OnInit } from '@angular/core';
import { Platform, MenuController, ToastController } from '@ionic/angular';
import { AlertControllerService } from './services/alert-controller.service';
import { NavigationService } from './services/navigation.service';
import { StorageService } from './services/storage.service';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
// import OneSignal from 'onesignal-cordova-plugin';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { ApiService } from './services/api.service';
import { initializeApp } from "firebase/app";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { Events } from './services/common/events.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  menuItems = [
    {
      title: 'Tutorial',
      url: '/tutorial',
    },
    {
      title: 'About Us',
      url: '/about-us',
    },
    {
      title: 'Contact Us',
      url: '/contact-us',
    },
    {
      title: 'Setting',
      url: '/setting',
    },

  ];
  deviceToken: any;
  uuid: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private alertControllerService: AlertControllerService,
    private storageService: StorageService,
    private naviGation: NavigationService,
    private menu: MenuController,
    private statusBar: StatusBar,
    private apiService: ApiService,
    private device: Device,
    public router: Router,
    private fcm: FCM,
    private zone: NgZone,
    private event: Events,
    private androidPermissions: AndroidPermissions,
  ) {
    this.initializeApp();
  }
  async ngOnInit() {
    // Code to get the web token to send notification using firebase 
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: 'BIzzwc5ngXivvgbtunePt3ATBHtR3lRgVxyorK07A0KGKKnlpwgYNWpuiDZhIMJeiufFujuqukyzWNVoc5mktBA' }).then((currentToken) => {
      if (currentToken) {
        console.log("currentToken", currentToken);
      } else {
        (messaging as any).requestPermission().then(() => {
          getToken(messaging, { vapidKey: 'BIzzwc5ngXivvgbtunePt3ATBHtR3lRgVxyorK07A0KGKKnlpwgYNWpuiDZhIMJeiufFujuqukyzWNVoc5mktBA' }).then((newToken) => {
            if (newToken) {
              console.log("currentToken", newToken);
            }
          });
        })
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

    onMessage(messaging, (data: any = []) => {
      console.log('Message received. ', data);
      if (!this.apiService.isPlatformAndroidIos) {
        if (data.notification.body) {
          this.alertControllerService.presentAlert(data.notification.body, 'Notification');
        }
      }
    });
  }

  async requestPushNotificationsPermission() {
    // Code to ask permission for the IOS devices to open permission promp when the app is installed
    const wasPermissionGiven: boolean = await this.fcm.requestPushPermission({
      ios9Support: {
        timeout: 10,  // How long it will wait for a decision from the user before returning `false`
        interval: 0.3 // How long between each permission verification
      },
    })
    console.log("wasPermissionGiven", wasPermissionGiven);
    // Code to get the device token to send notification using firebase 
    this.fcm.getToken().then((res: any) => {
      console.log("response>>>", res)
    });
    this.fcm.onNotification().subscribe(data => {
      var self = this;
      console.log("noti data", data);
      if (data.wasTapped) {
        console.log("Received in background");
        if (data.type === 'ITIN Interview') {
          this.zone.run(() => {
            self.router.navigate(['/guest-home']);
          });
        } else {
          this.zone.run(() => {
            self.router.navigate(['/notification']);
          });
        }
      } else {
        if (data.type === 'ITIN Interview') {
          self.router.navigate(['/guest-home']);
        } else {
          this.event.publish('refresh', true);
        }
        if (data.msgshow && this.apiService.isPlatformAndroidIos) {
          this.alertControllerService.presentAlert(data.msgshow, 'Notification');
        }
      };
    });

    this.fcm.onTokenRefresh().subscribe(token => {
      console.log("token******", token);
    });

    this.fcm.hasPermission().then(hasPermission => {
      if (hasPermission) {
        console.log("Has permission!");
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      const app = initializeApp(environment.firebaseConfig);
      //  this.OneSignalInit()
      if (this.platform.is('android')) {
        this.getPermission();
      }
      await this.requestPushNotificationsPermission();
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#004466');
      this.splashScreen.hide();
      this.redirectUser();

    });
  }

  async redirectUser() {
    this.storageService.getFromNativeStorage('gw_carter_user').then((user) => {
      if (user !== null) {
        this.naviGation.goToRoot('/');
      } else {
        this.naviGation.goToRoot('/login');
      }
    });
  }

  logout() {
    const alertHeader = 'Logout';
    const alertMessage = 'Are you sure ?';
    this.alertControllerService
      .presentAlertWithHeader(alertHeader, alertMessage)
      .then((userResponse) => {
        if (userResponse) {
          this.storageService.getFromNativeStorage('gw_carter_user').then(async (user) => {
            let login_user = JSON.parse(user);
            console.log("login_user", login_user);
            if (!this.apiService.isPlatformAndroidIos) {
              this.uuid = await this.storageService.getFromNativeStorage('device_uuid');
            } else {
              this.uuid = this.device.uuid;
            }
            let body = {
              token: login_user?.token,
              device_uuid: this.uuid
            };
            this.apiService.sendData('remove_device_details', body).subscribe((resp) => {
            }, (err) => {
              console.log(err);
            })
          });
          this.storageService.removeFromNativeStorage('gw_carter_user');
          this.menu.enable(false);
          this.naviGation.setRoot('/login');
        }
      });
  }

  menuToggle() {
    this.menu.close();
  }

  getPermission() {
    return new Promise<void>((resolve, reject) => {
      this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.READ_MEDIA_IMAGES, this.androidPermissions.PERMISSION.READ_MEDIA_AUDIO, this.androidPermissions.PERMISSION.READ_MEDIA_VIDEO, this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE, this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE]).then(
        success => {
          console.log("HELLO GRANTED", success);
          resolve();
        }).catch(error => {
          console.log("HELLO error", error);
          reject();
        });
    });
  }


  // OneSignalInit() {
  //   var self=this;
  //   console.log("OneSignalInit");
  //   OneSignal.setAppId("110b5330-a215-483f-aa63-90b1ba24f305");
  //   OneSignal.setNotificationOpenedHandler(function (jsonData) {
  //     console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  //     console.log(jsonData['notification']['additionalData']['type']);
  //     console.log(jsonData['notification']['additionalData']['uuid']);
  //     if(jsonData['notification']['additionalData']['type'] === 'ITIN Interview'){
  //       console.log("Hello");
  //       self.router.navigate(['/guest-home']);
  //     }else{
  //       self.router.navigate(['/notification']);
  //     }
  //   });

  //   OneSignal.setNotificationWillShowInForegroundHandler(function (data) {
  //     console.log('Notification received');
  //   });

  //   OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
  //     console.log("User accepted notifications: " + accepted);
  //   });

  //   setTimeout(() => {
  //     OneSignal.getDeviceState(res => {
  //       console.log("res: ", res);
  //       let temp: any = res;
  //       this.deviceToken = temp.userId;
  //     });
  //   }, 2000);
  // }
}
